import React from 'react';
import { graphql, Link } from 'gatsby';
import { generateReadyToPlayGuitarsUrl } from '../../lib/generateReadyToPlayGuitarsUrl';
import CTA from '../components/CTA';
import ProductCard from '../components/ProductCard';
import ProductsGrid from '../components/ProductsGrid';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import MediaText from '../components/MediaText';
import PageHeader from '../components/PageHeader';

const translations = [
  {
    locale: 'en',
    url: '/en/ready-to-play-guitars/',
  },
];

export default function GuitarePretesAJouerPage({ data }) {
  let {
    guitars: { nodes: guitars = [] },
  } = data;

  guitars = guitars.map((guitar) => ({
    name: guitar.name,
    image: {
      gatsbyImageData: guitar.featuredImage.asset.localFile.childImageSharp,
    },
    href: generateReadyToPlayGuitarsUrl('fr', guitar.slug.current),
  }));

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Guitares <br />
          prêtes à&nbsp;jouer
        </PageHeader>

        <MediaText left top media={data.readyToPlayImage}>
          <div className="max-w-prose">
            <Heading level={2} className="!mt-0">
              Une gamme de guitares haut&nbsp;de&nbsp;gamme
            </Heading>

            <p>
              Un bon verre de scotch à la main vous regardez l’œuvre sur le mur.
              Ce n’est pas une toile. Encore mieux, c’est une guitare. Et pas
              n’importe laquelle. C’est une guitare Pellerin prête à jouer. Vous
              la décrochez. Vous y posez vos doigts. Elle sonne parfaitement. Et
              vous avez tellement de plaisir à la faire vibrer que vos autres
              guitares deviennent jalouses.
            </p>
          </div>
        </MediaText>

        <div className="container text-xl text-center">
          <Heading level={2} className="text-5xl">
            Guitares Pellerin à vendre
          </Heading>

          <p className="max-w-prose mx-auto">
            Comme Michel Pellerin construit souvent deux guitares presque
            identiques en changeant seulement la sorte de bois, vous pouvez
            acheter la deuxième. Vous profitez de la même qualité qu’une guitare
            sur-mesure. Mais elle peut être livrée chez vous beaucoup plus
            rapidement. Ça sonne comme de la musique à vos oreilles?
          </p>
        </div>

        <section className="container">
          <ProductsGrid>
            {guitars.map((guitar) => (
              <ProductCard
                key={guitar.href}
                name={guitar.name}
                href={guitar.href}
                image={guitar.image}
              />
            ))}
          </ProductsGrid>
        </section>

        <CTA>
          <div className="max-w-prose">
            <Heading level={2} className="!mt-0">
              Vous préférez une guitare personnalisée?
            </Heading>

            <Link
              to="/guitares-personnalisees"
              className="underline font-medium text-xl"
            >
              Découvrez le processus.
            </Link>
          </div>
        </CTA>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    readyToPlayImage: file(name: { eq: "PellerinGuitares_011" }) {
      childImageSharp {
        gatsbyImageData(width: 1536, placeholder: BLURRED)
      }
    }

    guitars: allSanityReadyToPlay(sort: { fields: orderRank, order: ASC }) {
      nodes {
        slug {
          current
        }
        name
        featuredImage {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 744, height: 1044, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
